import React from 'react';
import { TextLoop } from 'react-text-loop-next';

export function LoopingObjectives() {
	return (
		<h1 className="max-w-6xl mx-auto mb-10 font-light text-center md:px-40">
			<span className="text-2xl md:text-2xl xl:text-3xl">Utilize feedback to</span>
			<br />
			<TextLoop
				interval={[1, 2000, 2000, 2000, 2000, 2000]}
				delay={500}
				springConfig={{ stiffness: 180, damping: 16 }}
				className="w-full font-semibold text-sky-500 text-loop-helper"
			>
				{/* This first one is a dummy placeholder to get the first loop to go faster (1ms interval with 500ms delay) */}
				<LoopedText text="grow revenue" />
				<LoopedText text="improve engagement" />
				<LoopedText text="expand your user base" />
				<LoopedText text="simplify user experience" />
				<LoopedText text="grow revenue" />
				<LoopedText text="(your objective here!)" />
			</TextLoop>
		</h1>
	);
}

function LoopedText({ text }) {
	return <span className="w-full text-2xl text-center md:text-3xl xl:text-4xl">{text}</span>;
}
