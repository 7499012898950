import React, { useEffect, useState } from 'react';

import { ExperimentRandom } from 'react-abtest';

import { GridContainer, ParentGridContainer } from './GridCard';
import { RegisterButtonLink } from './Register';

import MockupWidget from '../images/mockup-widget.webp';
import MockupValue from '../images/mockup-effort-high-low.webp';
import MockupFeedback from '../images/mockup-feedback.webp';
import EmbarkableConnect from '../images/embarkable-connect.webp';
import Integration from '../images/integration.webp';
import PinkWaves from '../images/stacked-waves-pink.svg';

//tag used for google analytics
const A = (
	<TitleBlockStack
		title="Connect with your Customers"
		subtitle="Embarkable is the simple way to get great user feedback."
		color="indigo"
		image=""
		tag="title-factory"
	/>
);

const B = (
	<TitleBlockStack
		// title="Schedule User Interviews without the logistics"
		title="User interviews without the logistics"
		// subtitle="The simple way to get user interviews for low-touch SaaS companies."
		subtitle="The simplest way for low-touch SaaS companies to connect with their customers to validate, learn, and grow."
		color="red-300"
		image=""
		tag="title-factory"
	/>
);

//Optional, but useful for logging test data.
//const logger = (variant) => console.log(`User placed in group ${variant}.`);

const ABCTitleBlock = () => {
	return (
		<ExperimentRandom
			variants={[A, B]}
			//logger={logger}
		/>
	);
};

export default ABCTitleBlock;

export function TitleBlockStack() {
	return (
		<React.Fragment>
			<div className="px-3 mb-12 title-container lg:p-0">
				<h1 className="mt-32 mb-10 lg:mt-48">
					Connect <strong className="text-purple-400">feedback</strong> to ideas and turn
					them into <strong className="text-orange-400">action</strong>,
					<br />
					<strong className="text-sky-400">2x faster</strong>
				</h1>

				<h2 className="mb-16 text-gray-600">
					Embarkable is the feedback management and product planning hub for nimble teams.
				</h2>

				<div className="flex justify-center w-full mx-auto">
					<RegisterButtonLink
						label="Try it free"
						eventLocation="hero"
						showCreditCardText
					/>
				</div>
			</div>
			<div className="relative w-full h-160">
				{/* <div className="w-2/3 py-24 m-auto h-128 bg-gradient-to-br from-rose-200 via-orange-200 to-rose-300"> */}
				<div className="w-full m-auto h-160 bg-gradient-to-br from-purple-200 via-sky-100">
					<div className="m-auto lg:w-2/3 lg:rounded-xl">
						<img
							className="bg-cover"
							src={EmbarkableConnect}
							alt="Embarkable's board view for ideas and features"
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export function LeftImageOverlay({}) {
	return (
		<React.Fragment>
			<div className="absolute z-10 w-2/3 p-3 -ml-10 lg:mx-auto bottom-24 lg:top-4 lg:left-10 lg:w-1/4">
				<img
					className="bg-cover lg:rounded-lg"
					src={MockupWidget}
					alt="Embarkable's board view for ideas and features"
				/>
				{/* <div className="">Capture Feedback -></div> */}
			</div>
		</React.Fragment>
	);
}

export function TopRightImageOverlay({}) {
	return (
		<div className="absolute right-0 z-20 w-2/3 p-3 lg:mx-auto bottom-52 lg:top-10 lg:right-12 lg:w-1/4">
			<img
				className="bg-cover lg:rounded-lg"
				src={MockupValue}
				alt="Embarkable's board view for ideas and features"
			/>
			{/* <div className="">Prioritize -></div> */}
		</div>
	);
}

export function LowerRightImageOverlay({}) {
	return (
		<div className="absolute right-0 z-10 w-2/3 p-3 mx-auto bottom-16 lg:bottom-10 lg:right-14 lg:w-1/4">
			<img
				className="bg-cover lg:rounded-lg"
				src={MockupFeedback}
				alt="Embarkable's board view for ideas and features"
			/>
			{/* <div className="">Prioritize -></div> */}
		</div>
	);
}

export function CenterImageOverlay({}) {
	return (
		<div className="absolute z-10 w-full mx-auto bottom-40 lg:bottom-16">
			<img
				className="w-full"
				src={PinkWaves}
				alt="Embarkable's board view for ideas and features"
			/>
			{/* <div className="">Prioritize -></div> */}
		</div>
	);
}

export function TitleBlockImage({ title, subtitle, color, image, CTA }) {
	return (
		<section className="w-full bg-gradient-to-br from-white via-sky-100 to-purple-100">
			<ParentGridContainer
				columns="2"
				isScreenHeight
				style="items-center gap-2 py-8"
				width="constrain-width-xl"
				// bgColor="bg-gradient-to-br from-white via-sky-100 to-purple-100"
			>
				<GridContainer style="py-16" width="">
					<h1 className="mb-4 lg:mb-10 mt-4 lg:mt-28">
						{/* Connect <strong className="text-purple-400">feedback</strong> to ideas and
						turn them into <strong className="text-red-400">action</strong>,
						<br />
						<strong className="line-through ">faster</strong>
						<strong className="underline text-sky-400"> while you sleep.</strong> */}
						One-click insights <br /> to go from feedback to action, faster
					</h1>

					<h2 className="mb-4 lg:mb-16 text-gray-600">
						Supercharge your feedback loop with the only product management platform
						powered by <span className="text-red-400">AI-enabled</span> user feedback
						analysis
						{/* Build your product roadmap with confidence by understanding user needs */}
					</h2>

					<div className="flex justify-center w-full mx-auto">
						<RegisterButtonLink
							label="Try it free"
							eventLocation="hero"
							showCreditCardText
						/>
					</div>
				</GridContainer>

				<GridContainer style="" round="" width="lg:w-5/6">
					<div className="">
						<img
							className="max-h-[30vh] lg:max-h-[75vh] mx-auto"
							src={Integration}
							alt="Embarkable connects feedback to ideas so that you can build an informed roadmap."
						/>
					</div>
					{/* <div className="absolute z-0 rounded-full bg-gradient-to-br from-white via-sky-100 to-purple-100 w-72 opacity-30 h-72 bottom-28 right-48" /> */}
					{/* <div className="absolute z-0 rounded-full bg-gradient-to-br from-purple-200 via-sky-100 to-purple-200 w-88 opacity-70 h-88 bottom-28 right-72" /> */}
				</GridContainer>
			</ParentGridContainer>
		</section>
	);
}
