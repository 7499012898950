import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

import {
	TitleBlockImage,
	LeftImageOverlay,
	TopRightImageOverlay,
	LowerRightImageOverlay,
	CenterImageOverlay
} from '../components/TitleBlock';
import { CtaSimple } from '../components/CTABlock';

import { GridContainer, ParentGridContainer } from '../components/GridCard';
import { LoopingObjectives } from '../components/LoopingObjectives';
import { StickyScrollBlock } from 'components/StickyScrollBlock';
import ImgBoardView from '../images/embark-board.webp';
import Integration from '../images/zapier-integration.webp';
import AiWaves from '../images/ai-waves.webp';

function IndexPage() {
	return (
		<Layout>
			<SEO pageTitle={'User Feedback and Product Planning Software'} />

			<div className="">
				<div className="relative overflow-hidden">
					{/* <div className="absolute z-0 hidden h-screen xl:block bg-gradient-to-b from-gray-500 to-white w-200 inset-x-2/3" /> */}

					<TitleBlockImage />
				</div>

				<ParentGridContainer columns="2" style="my-32 constrain-width-xl">
					<GridContainer bgColor="">
						<div className="px-8 text-left">
							<h1 className="mb-12">Bring feedback together.</h1>
							<div className="text-gray-900 xl:text-xl">
								Centralize feedback so that you can easily access user insights, and
								monitor a single stream of feedback.
								<br />
								<br />
								Automate the collection of every piece of feedback using
								integrations that match your workflow.
							</div>
						</div>
					</GridContainer>
					<GridContainer bgColor="">
						<div className="">
							<img
								className="bg-cover"
								src={Integration}
								alt="Embarkable integrations"
							/>
						</div>
					</GridContainer>
				</ParentGridContainer>

				<ParentGridContainer columns="2" style="my-32 constrain-width-xl">
					<GridContainer bgColor="">
						<div className="">
							<img
								className="bg-cover"
								src={AiWaves}
								alt="Embarkable's AI capabilities"
							/>
						</div>
					</GridContainer>
					<GridContainer bgColor="">
						<div className="px-8 text-left">
							<h1 className="mb-8">Filter the signal from the noise.</h1>
							{/* <h1 className="mb-8">An AI-powered Feedback Engine</h1> */}
							<div className="text-gray-900 xl:text-xl">
								Embarkable's AI technology scans your incoming communications and
								extracts feedback and insights. Feedback is then categorized and
								linked to product ideas. All, automatically.
							</div>
						</div>
					</GridContainer>
				</ParentGridContainer>

				<section className="w-full px-4 my-32 py-36 bg-gradient-to-br from-teal-50 via-sky-100 to-purple-100">
					<div className="title-container">
						<h1 className="mb-8">Reach your goals, faster.</h1>
						<LoopingObjectives />
						<div className="text-gray-900 text-xl constrain-width-md pb-8">
							Easily access key customer insights to help decide what to build, and
							why. <br /> <br />
							Prioritize ideas and build your product roadmap using data to back up
							your decisions, and drive your business forward.
						</div>
					</div>
				</section>

				{/* 
				<ParentGridContainer columns="3" style="mb-48 constrain-width-xl">
					<GridContainer
						bgColor="bg-gradient-to-tr from-purple-200 via-fuscia-200 to-pink-200"
						style="max-w-md"
					>
						<div className="p-8 text-left">
							<div className="mb-4 text-2xl font-semibold text-gray-800">
								Bring feedback together
							</div>
							<div className="text-gray-900 xl:text-xl">
								Centralize feedback from all sources, both external (your users) and
								internally across your entire team.
							</div>
						</div>
					</GridContainer>

					<GridContainer
						bgColor="bg-gradient-to-tr from-rose-200 via-orange-200 to-amber-200"
						style="max-w-md"
					>
						<div className="p-8 text-left">
							<div className="mb-4 text-2xl font-semibold leading-tight text-gray-800">
								Connect insights to ideas
							</div>
							<div className="text-gray-900 xl:text-xl">
								Link your insights to your feature requests and bugs, so that you
								have all the relevant information to make the best decisions.
							</div>
						</div>
					</GridContainer>

					<GridContainer
						bgColor="bg-gradient-to-tr from-sky-200 via-cyan-200 to-teal-200"
						style="max-w-md"
					>
						<div className="p-8 text-left">
							<div className="mb-4 text-2xl font-semibold text-gray-800">
								Reach your goals, faster
							</div>
							<div className="text-gray-900 xl:text-xl">
								Prioritize against business objectives to build a product roadmap
								and grow your business.
							</div>
						</div>
					</GridContainer>
				</ParentGridContainer> */}

				<div className="relative overflow-hidden my-28 xl:h-screen" id={''}>
					<div className="mb-16 pt-16 title-container">
						<h1 className="px-4 font-semibold">Your product command center.</h1>
						<h3 className="px-2 mt-2 xl:px-12">
							Embarkable brings together user insights, feature ideas, bug reports,
							product objectives, and roadmapping. <br />
							<br />
							Make informed product decisions, and be confident you are building the
							right product.
						</h3>
					</div>

					<div className="relative w-full lg:h-160">
						<div className="relative z-20 w-full m-auto lg:w-2/3 h-128 lg:py-28 ">
							<div className="p-3 m-auto lg:w-2/3 lg:rounded-xl">
								<img
									className="bg-cover shadow-xl"
									src={ImgBoardView}
									alt="Embarkable's board view for ideas and features"
								/>
							</div>
						</div>
						<CenterImageOverlay />
						<LeftImageOverlay />
						<TopRightImageOverlay />
						<LowerRightImageOverlay />
					</div>
				</div>

				{/* <StickyScrollBlock /> */}

				{/* <div
						className="relative overflow-hidden bg-red-500 lg:h-screen"
						id={'use-case'}
					>
						<div className="absolute bg-red-700 rounded-full w-400 h-400 -inset-x-1/2" />
						<div className="absolute -my-10 bg-red-600 rounded-full w-400 h-400 inset-x-1/5" />

						<ParentGridContainer columns="4">
							<GridContainer columns="4">
								<div className="px-4 my-8 text-3xl font-semibold text-center text-white text-opacity-75 md:text-5xl">
									Prioritize the features your customers need
								</div>
							</GridContainer>

							{pageData.useCase.map((usecase, index) => {
								return (
									<div key={index} className="grid-1">
										<TopImageCard bgColor="white" data={usecase} />
									</div>
								);
							})}
						</ParentGridContainer>
					</div>

					<ParentGridContainer style="my-48">
						<GridContainer>
							<div className="px-4 text-2xl font-semibold text-center text-gray-800 md:text-4xl">
								The simple solution to product prioritization and planning.
							</div>
							<div className="px-20 mt-2 text-base font-normal text-center text-gray-700 md:text-lg">
								Features designed to help you prioritize where to spend your time.
							</div>
						</GridContainer>

						<ParentGridContainer columns="4" width="w-full" style="">
							{pageData.featureList.map((feature, index) => {
								//const colorIndex = (index < 10 ? index : Math.round(index / 10))
								const rndInt = Math.floor(Math.random() * (10 - 1 + 1));
								//const rndHue = Math.floor(Math.random() * (9 - 1 + 1))
								const colorArray = [
									'gray',
									'red',
									'yellow',
									'green',
									'blue',
									'indigo',
									'purple',
									'pink',
									'teal',
									'orange',
									'tan'
								];
								//const colorHue = ['100', '200', '300', '400', '500', '600', '700', '800', '900']
								const iconColor = `${colorArray[rndInt]}`; //-${colorHue[rndHue]}`

								return (
									<GridContainer key={index} columns="1" style="">
										<IconCard data={feature} iconColor={iconColor} />
									</GridContainer>
								);
							})}
						</ParentGridContainer>
					</ParentGridContainer> */}

				<CtaSimple eventPageName="home" />
			</div>
		</Layout>
	);
}

export default IndexPage;
