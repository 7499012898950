import React from 'react';

export const ParentGridContainer = ({
	columns = 1,
	isScreenHeight,
	bgColor = 'none',
	width = 'w-full',
	style = 'py-12',
	children
}) => {
	return (
		<div
			className={`grid gap-12 grid-cols-1 lg:grid-cols-${columns}
			${isScreenHeight ? 'lg:h-screen' : ''} ${bgColor} relative z-0 px-6
			${style} ${width} mx-auto`}
		>
			{children}
		</div>
	);
};

export const GridContainer = ({
	columns = 1,
	isScreenHeight,
	bgColor = 'none',
	width = 'w-full',
	style = 'py-12',
	round = 'lg:rounded-2xl',
	children
}) => {
	return (
		<div
			className={`col-span-1 lg:col-span-${columns} 
			${isScreenHeight && 'lg:h-screen'}
			${bgColor} relative z-0
			${style} ${width} ${round}
			mx-auto overflow-hidden`}
		>
			{children}
		</div>
	);
};
